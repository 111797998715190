<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">Inventory Summary</h4>
      </div>
      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          v-b-modal.record-stock-modal
          :to="{
            name: 'inventory-edit-product',
            params: { id: this.$route.params.id },
          }"
          class="mr-2"
        >
          <feather-icon icon="EditIcon" size="16"></feather-icon>
          Edit
        </b-button>
        <b-button
          variant="dark"
          class="my-1"
          :to="{ path: '/inventory/create' }"
        >
          <feather-icon icon="TrashIcon" size="16" class="mr-25"></feather-icon>
          Delete
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="7">
        <b-card
          title="Product Image"
          style="min-height: 320px; max-height: 400px"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <img
              :src="product.product_images[0].image_path"
              alt="Product Image"
              class="img-thumbnail"
              style="width: 360px; height: 230px"
            />
          </div>
        </b-card>
        <b-card
          title="Basic Information"
          style="min-height: 200px; max-height: 400px"
        >
          <p>
            <span class="font-weight-bold text-primary">Category:</span>
            {{ product.product_category }}
          </p>
          <p>
            <span class="font-weight-bold text-primary"
              >Short Description:</span
            >
            {{ product.short_description }}
          </p>
          <p>
            <span class="font-weight-bold text-primary">Long Description:</span>
            {{ product.long_description }}
          </p>
          <p>
            <span class="font-weight-bold text-primary">Created By:</span>
            {{ product.added_by }}
          </p>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-card style="min-height: 200px; max-height: 400px">
          <div class="text-center">
            <p class="mb-0 text-primary" style="font-size: 1.25rem">
              <b>Quantity at Hand</b>
            </p>
            <h2 class="text-primary" style="font-size: 2.5rem">
              {{ product.remaining_quantity }}
            </h2>
            <p class="mb-0">
              <u>Adjust stock</u>
            </p>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <div>
              <p class="mb-0">Warehouse Puchong HQ</p>
              <p class="mb-0">Warehouse Johor Bahru</p>
              <p class="mb-0">Warehouse Taiping</p>
            </div>
            <div>
              <p class="mb-0">???</p>
              <p class="mb-0">???</p>
              <p class="mb-0">???</p>
            </div>
          </div>
        </b-card>
        <b-card style="min-height: 200px; max-height: 400px">
          <div class="text-primary" style="font-size: 1.25rem">
            <p class="mb-3"><b>Total Orders</b></p>
          </div>
          <div class="mb-3">
            <p class="mb-0">Purchase</p>
            <h5 class="mb-0">???</h5>
          </div>
          <div class="mb-3">
            <p class="mb-0">Sales</p>
            <h5 class="mb-0">???</h5>
          </div>
          <div>
            <p class="mb-0">Profit</p>
            <h5 class="mb-0">???</h5>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card
          class="d-flex flex-column justify-content-between"
          style="min-height: 200px; max-height: 400px"
        >
          <div>
            <p class="font-weight-bold text-primary">
              <b>Purchase Information</b>
            </p>
            <p><span class="text-primary">Cost:</span> RM ???</p>
          </div>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card
          class="d-flex flex-column justify-content-between"
          style="min-height: 200px; max-height: 400px"
        >
          <div>
            <p class="font-weight-bold text-primary">
              <b>Sales Information</b>
            </p>
            <p><span class="text-primary">Margin:</span> ???</p>
            <p><span class="text-primary">Profit:</span> RM ???</p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="text-primary">History</h3>
        <b-button variant="success" @click="exportTable">
          <feather-icon
            icon="DownloadIcon"
            size="16"
            class="mr-2"
          ></feather-icon>
          Export
        </b-button>
      </div>
      <b-form-group>
        <b-form-radio-group
          v-model="transactionType"
          name="transactionType"
          buttons
          class="w-100"
          button-variant="outline-primary"
        >
          <b-form-radio value="in" class="w-50" button-variant="primary"
            >In</b-form-radio
          >
          <b-form-radio value="out" class="w-50" button-variant="primary"
            >Out</b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
      <MyTable
        :columns="columns"
        :rows="rows"
        :isLoading="isLoading"
        :checkboxEnabled="true"
      >
        <template v-slot="{ props }">
          <div
            v-if="props.column.field === 'action'"
            class="d-flex justify-content-center"
          >
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'inventory-product-detail',
                  params: { id: props.row.id },
                }"
              >
                <span>Details</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'inventory-edit-product',
                  params: { id: props.row.id },
                }"
              >
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(props.row)">
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </MyTable>
    </b-card>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  data() {
    return {
      product: {},
      columns: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Order Date",
          field: "date_adjusted",
          tdClass: "text-center align-middle",
        },
        {
          label: "Location",
          field: "product_category",
          tdClass: "text-center align-middle",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "text-center align-middle",
        },
        {
          label: "Quantity",
          field: "quantity",
          tdClass: "text-center align-middle",
        },
        {
          label: "Grand Total",
          field: "total_value",
          tdClass: "text-center align-middle",
        },
        // {
        //   label: "Action",
        //   field: "action",
        //   tdClass: "text-center align-middle",
        // },
      ],
      rows: [],
      required,
    };
  },

  created() {
    this.fetchProduct();
  },

  methods: {
    async fetchProduct() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(
          `inventory/admin/products/${this.$route.params.id}`
        );
        this.product = response.data.data;
        this.rows = this.product.product_inventory_adjustments;
      } catch (error) {
        console.error("Error fetching products:", error);
        this.rows = [];
      } finally {
        this.isLoading = false;
      }
    },

    submitStock() {
      // Your submit logic here
    },

    async deleteItem(row) {
      try {
        const response = await this.$http.delete(`inventory/admin/products/2`);
        if (response.status === 200) {
          // Handle successful deletion
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: "Deleted successfully",
            },
          });
          // Fetch updated products list
          this.fetchProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "XIcon",
              variant: "warning",
              text: "Delete failed",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

